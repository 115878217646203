import { createAction, props } from '@ngrx/store';
import { CustomersModel } from './consumer.model';

// fetch
export const getConsumer = createAction(
  '[Consumer] fetch Customer',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
    criteria?: string;
  }>()
);
export const getConsumerSuccess = createAction(
  '[Consumer] fetch Customer success',
  props<{ getConsumersResponse: any }>()
);
export const getConsumerrFail = createAction(
  '[Consumer[ fetch Customer failed]',
  props<{ getConsumersError: any }>()
);

// fetch Consumer Export
export const getConsumerExport = createAction(
  '[Consumer] fetch Customer export',
  props<{ ProjectId: string; criteria?: string }>()
);
export const getConsumerExportSuccess = createAction(
  '[Consumer] fetch Customer export success',
  props<{ getConsumersExportResponse: any }>()
);
export const getConsumerExportrFail = createAction(
  '[Consumer[ fetch Customer  Export failed]',
  props<{ getConsumersExportError: any }>()
);

// fetch consumer Meter Export
export const getConsumerMeterExport = createAction(
  '[Consumer] fetch Customer Meter export',
  props<{ ProjectId: string; criteria?: string }>()
);
export const getConsumerMeterExportSuccess = createAction(
  '[Consumer] fetch Customer Meter export success',
  props<{ getConsumersMeterExportResponse: any }>()
);
export const getConsumerMeterExportrFail = createAction(
  '[Consumer[ fetch Customer Meter  Export failed]',
  props<{ getConsumersMeterExportError: any }>()
);

// fetch consumer Meter ON/OFF Export
export const getConsumerMeteOnOffExport = createAction(
  '[meter] fetch Customer Meter OnOff export',
  props<{ criteria?: string }>()
);
export const getConsumerMeterOnOffExportSuccess = createAction(
  '[Meter] fetch Customer Meter OnOff export success',
  props<{ getConsumersMeterOnOffExportResponse: any }>()
);
export const getConsumerMeterOnOffExportrFail = createAction(
  '[Meter] fetch Customer Meter OnOff  Export failed]',
  props<{ getConsumersMeterOnOffExportError: any }>()
);

// fetch consumption History
export const getConsumptionHistory = createAction(
  '[Consumer consumptionHistory] fetch Customer consumptionHistory',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
    MeterId?: string;
  }>()
);
export const getConsumptionHistorySuccess = createAction(
  '[Consumer consumptionHistory]  fetch Customer consumptionHistory success',
  props<{ getconsumptionHistoryResponse: any }>()
);
export const getConsumptionHistoryFail = createAction(
  '[Consumer consumptionHistory] fetch Customer consumptionHistory failed]',
  props<{ getconsumptionHistoryError: any }>()
);

// fetch consumption History Export
export const getConsumptionHistoryExport = createAction(
  '[Consumer consumptionHistory Export] fetch Customer consumptionHistory Export',
  props<{ ProjectId: string; criteria?: string, MeterId?: string }>()
);
export const getConsumptionHistoryExportSuccess = createAction(
  '[Consumer consumptionHistory Export]  fetch Customer consumptionHistory Export success',
  props<{ getconsumptionHistoryExportResponse: any }>()
);
export const getConsumptionHistoryExportFail = createAction(
  '[Consumer consumptionHistory Export] fetch Customer consumptionHistory Export failed]',
  props<{ getconsumptionHistoryExportError: any }>()
);

// fetch consumer meter  deatils
export const getmeterDeatails = createAction(
  '[Consumer meterDeatails] fetch Customer meterDeatails'
);
export const getmeterDeatailsSuccess = createAction(
  '[Consumer meterDeatails]  fetch Customer meterDeatails success',
  props<{ getmeterDeatailsResponse: any }>()
);
export const getmeterDeatailsFail = createAction(
  '[Consumer meterDeatails] fetch Customer meterDeatails failed]',
  props<{ getmeterDeatailsError: any }>()
);

// fetch consumer meter  Controls
export const getmeterControls = createAction(
  '[Consumer meterControls] fetch Customer meterControls',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getmeterControlsSuccess = createAction(
  '[Consumer meterControls]  fetch Customer meterControls success',
  props<{ getmeterControlsResponse: any }>()
);
export const getmeterControlsFail = createAction(
  '[Consumer meterControls] fetch Customer meterControls failed]',
  props<{ getmeterControlsError: any }>()
);

// fetch consumer meter On/Off   history
export const getMeterOnOffControls = createAction(
  '[Consumer meterOnOffControls] fetch Customer  meter OnOffControls',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    FromDate: string;
    ToDate: string;
    orderBy?: string;
  }>()
);
export const getMeterOnOffControlsSuccess = createAction(
  '[Consumer meterOnOffControls]  fetch Customer meter OnOff Controls success',
  props<{ getMeterOnOffControlsResponse: any }>()
);
export const getMeterOnOffControlsFail = createAction(
  '[Consumer meterOnOffControls] fetch Customer meter OnOff Controls failed]',
  props<{ getMeterOnOffControlsError: any }>()
);



// fetch consumer dub bills
export const getDueBills = createAction(
  '[Consumer DueBills ] fetch Customer DueBills ',
  props<{ ProjectId: string }>()
);
export const getDueBillsSuccess = createAction(
  '[Consumer DueBills ]  fetch Customer DueBills  success',
  props<{ getDueBillsResponse: any }>()
);
export const getDueBillsFail = createAction(
  '[Consumer DueBills ] fetch Customer DueBills  failed]',
  props<{ getDueBillsError: any }>()
);

// fetch  bills deatils  By Id
export const getBilldeatils = createAction(
  '[Consumer Billdeatils] fetch Customer Billdeatils',
  props<{ BillNo: string }>()
);
export const getBilldeatilsSuccess = createAction(
  '[Consumer Billdeatils]  fetch Customer Billdeatils success',
  props<{ getBilldeatilsResponse: any }>()
);
export const getBilldeatilsFail = createAction(
  '[Consumer Billdeatils] fetch Customer Billdeatils failed]',
  props<{ getBilldeatilsError: any }>()
);

// fetch consumer bills History
export const getBillHistory = createAction(
  '[Consumer Billdeatils] fetch Customer Bill History',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getBillHistorySuccess = createAction(
  '[Consumer BillHistory]  fetch Customer BillHistory success',
  props<{ getBillHistoryResponse: any }>()
);
export const getBillHistoryFail = createAction(
  '[Consumer BillHistory] fetch Customer BillHistory failed]',
  props<{ getBillHistoryError: any }>()
);

// fetch consumer bills History  Export
export const getBillHistoryExport = createAction(
  '[Consumer Billdeatils] fetch Customer  Bill History Export ',
  props<{ ProjectId: string; criteria?: string }>()
);
export const getBillHistoryExportSuccess = createAction(
  '[Consumer BillHistory]  fetch Customer BillHistory  Export success',
  props<{ getBillHistoryExportResponse: any }>()
);
export const getBillHistoryExportFail = createAction(
  '[Consumer BillHistory] fetch Customer BillHistory  Export failed]',
  props<{ getBillHistoryExportError: any }>()
);

// fetch cons  Recharge History
export const getConsumerRechargeHistory = createAction(
  '[Consumer RechargeHistory] fetch Customer RechargeHistory',
  props<{
    ProjectId: string;
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getConsumerRechargeHistorySuccess = createAction(
  '[Consumer RechargeHistory]  fetch Customer RechargeHistory success',
  props<{ getConsumersRechargeHistoryResponse: any }>()
);
export const getConsumerRechargeHistoryFail = createAction(
  '[Consumer RechargeHistory] fetch Customer RechargeHistory failed]',
  props<{ getConsumersRechargeHistoryError: any }>()
);

// fetch cons  Recharge History Export
export const getConsumerRechargeHistoryExport = createAction(
  '[Consumer RechargeHistory Export] fetch Customer RechargeHistory Export',
  props<{ ProjectId: string; criteria?: string }>()
);
export const getConsumerRechargeHistoryExportSuccess = createAction(
  '[Consumer RechargeHistory Export]  fetch Customer RechargeHistory Export success',
  props<{ getConsumersRechargeHistoryExportResponse: any }>()
);
export const getConsumerRechargeHistoryExportFail = createAction(
  '[Consumer RechargeHistory Export] fetch Customer RechargeHistory Export failed]',
  props<{ getConsumersRechargeHistoryExportError: any }>()
);

// fetch consumer  Faq
export const getConsumerFaq = createAction('[Consumer Faq] fetch Customer Faq');
export const getConsumerFaqSuccess = createAction(
  '[Consumer Faq]  fetch Customer Faq success',
  props<{ getConsumersFaqResponse: any }>()
);
export const getConsumerFaqFail = createAction(
  '[Consumer Faq] fetch Customer Faq failed]',
  props<{ getConsumersFaqError: any }>()
);

// fetch consumer  Update Meter Statsu
export const updateMeerStatus = createAction(
  '[Consumer updateMeerStatus] fetch Customer updateMeerStatus',
  props<{ meterId: any; meterStatus: boolean }>()
);
export const updateMeerStatusSuccess = createAction(
  '[Consumer updateMeerStatus]  fetch Customer updateMeerStatus success',
  props<{ updateMeerStatusResponse: any }>()
);
export const updateMeerStatusFail = createAction(
  '[Consumer updateMeerStatus] fetch Customer updateMeerStatus failed]',
  props<{ updateMeerStatusError: any }>()
);

//store reset
export const resetState = createAction('[App] Reset State');
//store reset
export const resetBillHistoryState = createAction(
  '[App] Reset State Bill History'
);

//store reset
export const resetBillRechargeHistoryState = createAction(
  '[App] Reset  BRecharge HistoryState'
);



//store reset
export const resetBillmeterControlsState = createAction('[App] Reset State');

//store reset
export const resetBillonOffControlsState = createAction('[App] Reset State');

//store reset
export const resetConsumptionHistoryState = createAction('[App] Reset State');

// Create User List
export const createConsumer = createAction(
  '[Consumer] CreateUser',
  props<{ consumerData: CustomersModel }>()
);
export const addConsumerSuccess = createAction(
  '[Consumer] Consumer Success',
  props<{ createConsumerResponse: any }>()
);
export const addConsumerFailure = createAction(
  '[Consumer] Consumer Failure',
  props<{ createConsumerError: any }>()
);

// Update Data
export const updateConsumer = createAction(
  '[Consumer] Update Consumer',
  props<{ updatedData: CustomersModel; id: string }>()
);
export const updateConsumerSuccess = createAction(
  '[Consumer] Update Consumer Success',
  props<{ updateConsumerResponse: any }>()
);
export const updateConsumerFailure = createAction(
  '[Consumer] Update Consumer Failure',
  props<{ updateConsumerError: any }>()
);

// Delete Data
export const deleteConsumer = createAction(
  '[Consumer] Delete Consumer',
  props<{ id: string; projectId: string }>()
);
export const deleteConsumerSuccess = createAction(
  '[Consumer] Delete Consumer Success',
  props<{ deleteConsumerResponse: any }>()
);
export const deleteConsumerFailure = createAction(
  '[Consumer] Delete Consumer Failure',
  props<{ deleteConsumerError: any }>()
);

export const getConsumerStstusId = createAction(
  '[ConsumerStatus] fetch Customer',
  props<{ id: string; status: boolean }>()
);
export const getConsumerStatusSuccess = createAction(
  '[ConsumerStatus] fetch Customer success',
  props<{ getConsumerStatusResponse: any }>()
);
export const getConsumerSttausrFail = createAction(
  '[Consumer Status] fetch Customer failed',
  props<{ getConsumerStatusError: any }>()
);
export const resetrechargeHistoryState = createAction(
  '[resetrechargeHistoryState ] resetrechargeHistoryState'
);


//super admin  paymets:


export const getsettlement = createAction(
  '[settlement] fetch settlement ',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getsettlementSuccess = createAction(
  '[settlement]  fetch Customer settlement success',
  props<{ getsettlementResponse: any }>()
);
export const getsettlementFail = createAction(
  '[settlement] fetch Customer settlement failed]',
  props<{ getsettlementError: any }>()
);


// add Settlment 
export const retrysettlement = createAction(
  '[settlement] add settlement ',
  props<{ settlementData: any }>()
);
export const retrysettlementSuccess = createAction(
  '[settlement]  add settlement success',
  props<{ retrysettlementResponse: any }>()
);
export const retrysettlementFail = createAction(
  '[settlement] add  settlement failed]',
  props<{ retrysettlementError: any }>()
);

// check Status 
export const checkStatussettlement = createAction('[settlement] Check Status  settlement ', props<{ transferId: any }>())
export const checkStatusettlementSuccess = createAction(
  '[settlement]  Check Status settlement success',
  props<{ checkStatusettlementResponse: any }>()
);
export const checkStatusettlementFail = createAction(
  '[settlement] Check Status  settlement failed]',
  props<{ checkStatusettlementError: any }>()
);





//super admin  paymets: Export to Excel
export const getsettlementExport = createAction(
  'settlementExport] fsettlement Export ',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getsettlementExportSuccess = createAction(
  '[settlementExport]  fesettlement Export  Export success',
  props<{ getsettlementExportResponse: any }>()
);
export const getsettlementExportFail = createAction(
  '[settlementExport] fsettlement Export  Export failed]',
  props<{ getsettlementExportError: any }>()
);



export const resetsettlementExportState = createAction(
  '[App] Reset Ssettlement Export'
);







// update  consumer meter details 
export const updateConsumerMeterDetails = createAction(
  '[Consumer updateConsumerMeterDetails] update Customer updateConsumerMeterDetails', props<{ updaedMeterData: any }>());
export const updateConsumerMeterDetailstSuccess = createAction(
  '[Consumer updateConsumerMeterDetails]  update Customer updateConsumerMeterDetailssuccess', props<{ updateConsumerMeterDetailssuccessResponse: any }>());
export const updateConsumerMeterDetailsFail = createAction(
  '[Consumer updateConsumerMeterDetails] update Customer updateConsumerMeterDetailsfailed]', props<{ updateConsumerMeterDetailssuccessError: any }>());

export const resetupdateConsumerMeterDetails = createAction('[Customer State ] Reset State');

getBilldeatils

export const resegetBilldeatils = createAction('[get bills State ] Reset State');
