import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WalletState } from './wallet.reducer';

export const getWalletState = createFeatureSelector<WalletState>('wallet');

export const getWalletResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getWalletResponse
);

export const getWalletHistoryResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getWalletHistoryResponse
);

export const getrechargeHistoryResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getRechargeHistoryResponse
);
export const walletHistroeyResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.walletHistroeyResponse
);

export const getAllWalletResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getAllWalletResponse
);
export const getAllWalletHistoryResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getAllWalletHistoryResponse
);

export const getAllRechargeHistoryResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.getAllRechargeHistoryResponse
);
export const walletPyByCashResponse = createSelector(
  getWalletState,
  (state: WalletState) => state.walletPyByCashResponse
);
//
