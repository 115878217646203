import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsumerState } from './consumer.reducer';

export const selectDataState = createFeatureSelector<ConsumerState>('consumer');

export const getConsumersResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersResponse
);

export const createConsumerResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.createConsumerResponse);

export const deleteConsumerResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.deleteConsumerResponse
);

export const getConsumersRechargeHistoryResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersRechargeHistoryResponse
);

export const getConsumersFaqResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersFaqResponse
);

export const getconsumptionHistoryResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getconsumptionHistoryResponse
);
export const getConsumersExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersExportResponse
);
export const getmeterDeatailsResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getmeterDeatailsResponse
);

export const getmeterControlsResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getmeterControlsResponse
);

export const getConsumersMeterExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersMeterExportResponse
);

export const getConsumersRechargeHistoryExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersRechargeHistoryExportResponse
);

export const getconsumptionHistoryExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getconsumptionHistoryExportResponse
);


export const getDueBillsResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getDueBillsResponse
);

export const getBillHistoryResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getBillHistoryResponse
);



export const getBillHistoryExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getBillHistoryExportResponse
);

export const getBilldeatilsResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getBilldeatilsResponse
);

export const getConsumersMeterOnOffExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getConsumersMeterOnOffExportResponse
);

export const updateConsumerMeterDetailssuccessResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.updateConsumerMeterDetailssuccessResponse
);

export const getMeterOnOffControlsResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getMeterOnOffControlsResponse
);

export const getsettlementResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getsettlementResponse
);


export const getsettlementExportResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.getsettlementExportResponse
);


export const checkStatusettlementResponse = createSelector(
    selectDataState,
    (state: ConsumerState) => state.checkStatusettlementResponse
);




//


