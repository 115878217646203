// notification.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    // feedback notfication
    private feedbackUpdateSubject = new Subject<any>();

    // Observable for feedback updates
    feedbackUpdate$ = this.feedbackUpdateSubject.asObservable();

    // Method to notify about a feedback update
    notifyFeedbackUpdate(data: any) {
        this.feedbackUpdateSubject.next(data);
    }



    //recharge notfication
    private rechargeUpdateSubject = new Subject<any>();
    rechargeUpdate$ = this.rechargeUpdateSubject.asObservable();
    notifyRechargeUpdate(data: any) {
        this.rechargeUpdateSubject.next(data);
    }


    //meteron/off notfication
    private meterOnOffUpdateSubject = new Subject<any>();
    meterOnOffUpdate$ = this.meterOnOffUpdateSubject.asObservable();
    notifyMeterOnOffUpdate(data: any) {
        this.meterOnOffUpdateSubject.next(data);
    }

    
    //Invoice_Generation notfication
    private invoiceGenerationUpdateSubject = new Subject<any>();
    invoiceGenerationUpdate$ = this.invoiceGenerationUpdateSubject.asObservable();
    notifyInvoiceGeneratiorUpdate(data: any) {
        this.invoiceGenerationUpdateSubject.next(data);
    }

    private billHistoryUpdateSubject = new Subject<any>();
   billHistoryUpdate$ = this.billHistoryUpdateSubject.asObservable();
    notifyBillHistoryUpdate(data: any) {
        this.billHistoryUpdateSubject.next(data);
    }


    private mcbTurnOffOnUpdateSubject = new Subject<any>();
   mcbTurnOffOnUpdate$ = this.mcbTurnOffOnUpdateSubject.asObservable();
     notifymcbTurnOffOnUpdate(data: any) {
         this.mcbTurnOffOnUpdateSubject.next(data);
     }


     
    private highConsumptionnUpdateSubject = new Subject<any>();
    highConsumptionnUpdateSubjectUpdate$ = this.highConsumptionnUpdateSubject.asObservable();
      notifyhighConsumptionnUpdateSubjectUpdate(data: any) {
          this.highConsumptionnUpdateSubject.next(data);
      }
}
