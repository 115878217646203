import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Master } from '../../store/Masters/masters.models';

@Injectable({ providedIn: 'root' })
export class WalletService {
  constructor(private http: HttpClient, private router: Router) {}
  /* private walletHistoryDetails: BehaviorSubject<any> = new BehaviorSubject<User | null>(null);

  public walletHistory$: Observable<User | null> = this.walletHistoryDetails.asObservable();

  public setwalletHistory(wallet: any): void {
    this.walletHistoryDetails.next(wallet);
  } */

  getWallets(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    projectId: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getWallets + projectId}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getWallet failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getWalletsHistory(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    walletId: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getWalletsHistory + walletId}?pageIndex=${pageIndex}` +
      `${searchTerm ? `&Search=${searchTerm}` : ''}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getWalletsHistory failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getRechargeHistory(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    walletId: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getRechargeHistory + walletId}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&Search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getRechargeHistory failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getwalletHistory(consumerId: any, proectId: any): Observable<any> {
    return this.http
      .get<any>(AppConfigData.walletHistory + consumerId + '/' + proectId)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getRechargeHistory failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  walletpayBycash(cashDeatils: any): Observable<any> {
    let url;
    url = `${AppConfigData.walletPaybyCashUrl}`;
    return this.http.post<any>(url, cashDeatils).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('adimin_dashboard_data_BasedonDate failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
