export const metersData = [
    { Utility: '15 :34', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'2321', Currentdate:'07-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind', Offrequestsent:'No'},
    { Utility: '08 : 23', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'3423', Currentdate:'01-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Varanasi', Offrequestsent:'No'},
    { Utility: '08 : 23', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'4332', Currentdate:'16-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Shahjahanpur',Offrequestsent:'No' },
    { Utility: '10: 01', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'3242', Currentdate:'03-04-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: '10: 01', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'4544', Currentdate:'25-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Bhind',Offrequestsent:'Yes' },
    { Utility: '10: 01', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'4533', Currentdate:'01-02-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Varanasi',Offrequestsent:'No' },
    { Utility: '08 : 23', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'6754', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Shahjahanpur', Offrequestsent:'No'},
    { Utility: '15 :34', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant2', Onrequestsent:'No', ResidentialUnit:'Pathankot',Offrequestsent:'No' },
    { Utility: '10: 01', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'6755', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Bhind', Offrequestsent:'Yes'},
    { Utility: '15 :34', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'6544', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Varanasi',Offrequestsent:'Yes' },
    { Utility: '15 :34', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'4534', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'Yes', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    { Utility: '08 : 23', MacAddress: 'Status Bits1', MeterStatus: '6727', CurrentReading:'5434', Currentdate:'11-03-2024', Occupant:'Sample Occupant1', Onrequestsent:'No', ResidentialUnit:'Pathankot', Offrequestsent:'No' },
    
];