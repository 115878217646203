<div class="container-fluid">
    <section *ngIf="billList && billList.length>0">
        <div class="row">
            <div class="col-lg-12">
                <accordion [closeOthers]="false" class="accordion custom-accordian-bill" [isAnimated]="true">
                    @if(billGenerationType =='Combined'){
                    <div *ngFor="let bills of billList; let i = index">
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item5>
                            <div accordion-heading class="d-flex flex-grow-1">

                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                            <span class="text-primary font-size-16">Bill Details ({{bills?.utility}})

                                            </span>
                                        </div>

                                    </div>


                                    <div class="row mx-2 mt-3 text-accordian">
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Bill Date</label>
                                                <p>{{bills?.billGeneratedDate!=null?
                                                    (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                    :'' }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Due Date</label>
                                                <p>{{bills.dueDate!=null?
                                                    (bills.dueDate | date:'d MMMM yyyy')
                                                    :'' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Subtotal</label>
                                                <p>
                                                    {{bills?.billAmount!=undefined?(bills.billAmount
                                                    |currency:'INR':true):'0'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item5?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-16">Consumer
                                            Details</p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                            <!-- gas -->

                            <div class="row" *ngIf="bills?.gas.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/gas.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Gas Details : (Meter No :{{
                                        bills.gas[0].macAddress}})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.gas.length>0">
                                <div class="row" *ngFor="let gas of bills?.gas">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.previousReadingDate!=null?
                                                            (gas?.previousReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.currentReadingDate!=null?
                                                            (gas?.currentReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousMeterReading!=undefined?gas.previousMeterReading
                                                            +' m³' :'-'}}

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentMeterReading!=undefined?gas.currentMeterReading
                                                            +' m³':'-'}}

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 "></div>
                                            <div class=" ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.noofUnitsConsumed?gas.noofUnitsConsumed:'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.unitRate!=undefined?(gas.unitRate
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                    <div class="row" *ngIf="gas?.otherCharges?.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of gas.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{gas.billAmount!=undefined?(gas.billAmount
                                                |currency:'INR':true):'0'}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- water -->
                            <div class="row" *ngIf="bills?.water.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/water.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Water Details : (Meter No :{{
                                        bills?.water[0]?.macAddress}})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.water.length>0">
                                <div class="row" *ngFor="let water of bills?.water">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end">{{water?.previousBillDate!=null?
                                                            (water?.previousBillDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{water?.currentReadingDate!=null?
                                                            (water?.currentReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.previousMeterReading?water.previousMeterReading +'
                                                            L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentMeterReading?water.currentMeterReading +'
                                                            L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                            </div>
                                            <div class=" ">


                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.noofUnitsConsumed?water.noofUnitsConsumed:'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.unitRate!=undefined?(water.unitRate
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="row" *ngIf="water?.otherCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of water?.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{water?.billAmount!=undefined?(water.billAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- electricity -->
                            <div class="row" *ngIf="bills?.electricity.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/electricity.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Electricity Details : (Meter No :
                                        {{bills?.water[0]?.macAddress}})</span>
                                </div>
                            </div>
                            <div *ngIf="bills?.electricity.length>0">
                                <div *ngFor="let ele of bills?.electricity">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" pt-2 ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingEB!=undefined?ele.electricityPreviousMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading
                                                                DG</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingDG
                                                                !=undefined?ele.electricityPreviousMeterReadingDG +'
                                                                kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingEB!=undefined?ele.electricityCurrentMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingDG!=undefined?ele.electricityCurrentMeterReadingDG
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">

                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No. Of Units Consumed EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedEB!=undefined?ele.noofElectricityUnitsConsumedEB:'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedDG!=undefined?ele.noofElectricityUnitsConsumedDG:'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateEB!=undefined?(ele.unitRateEB
                                                                |currency:'INR':true):'0'}}</p>

                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateDG!=undefined?(ele.unitRateDG
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Reading Date
                                                            </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.previousReadingDate!=null?
                                                                (ele?.previousReadingDate | date:'d MMMM yyyy')
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Reading Date </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.currentReadingDate!=null?
                                                                (ele?.currentReadingDate | date:'d MMMM yyyy')
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Bill Amount</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                                |currency:'INR':true):'0'}}
                                                            </p>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="ele?.otherCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of ele?.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- other Charges -->
                            <div class="row" *ngIf="bills.generalCharges.length>0">
                                <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                    <img src="../../../../assets/images/brands/rupee.png">
                                    <span class="font-weight-bold ms-3 font-size-15">Other Charges </span>
                                </div>
                            </div>
                            <div>
                                <div class="row" *ngIf="bills.generalCharges.length>0">
                                    <table class="table custom-border-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Service Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">GST (%)</th>
                                                <th scope="col">GST Amount</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let charge of bills.generalCharges">
                                                <td>{{charge.name}}</td>
                                                <td>{{charge.amount}}</td>
                                                <td>{{charge.gst}}</td>
                                                <td>{{charge.gstAmt}}</td>
                                                <td>{{charge.total}}</td>

                                            </tr>
                                            <tr>
                                                <th scope="row"></th>
                                                <td colspan="1"></td>
                                                <td></td>
                                                <td class="font-weight-bolder">Total Amount</td>
                                                <td class="font-weight-bolder">
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    |currency:'INR':true):'0'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 ">
                                    <div class="d-flex justify-content-around penaltyBill">
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmountwithoutpenalty!=undefined?(bills.totalAmountwithoutpenalty
                                                |currency:'INR':true):'0'}}</p>
                                        </div>
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalPenalty!=undefined?(bills?.totalPenalty
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                        <div class="text-center">
                                            <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                            <!--  <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                            {{bills?(bills.totalGeneralCharge+bills.billAmount+bills?.totalPenalty
                                            |currency:'INR':true):'0'}} </p> -->
                                            <p class="font-size-18 font-weight-bold">
                                                {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                |currency:'INR':true):'0'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </accordion-group>
                    </div>
                    }@else {
                    <!-- individual Bills  -->

                    <div *ngFor="let bills of billList; let i = index">
                        <!-- Gas -->

                        @if(bills?.gas.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item2>

                            <div accordion-heading class="d-flex flex-grow-1">

                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/water.png">
                                            <span class="text-primary font-size-15">Gas : Billing
                                                Cart({{bills?.billNo}})
                                            </span>
                                        </div>

                                    </div>
                                    <div *ngFor="let gas of bills?.gas">

                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{gas?.dueDate!=null?
                                                        (gas?.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Units Consumed</label>
                                                    <p>{{gas?.noofUnitsConsumed?gas.noofUnitsConsumed:'-'}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>

                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        |currency:'INR':true):'0'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer
                                            Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf="bills?.gas.length>0">
                                <div class="row" *ngFor="let gas of bills?.gas">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.previousReadingDate!=null?
                                                            (gas?.previousReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{gas?.currentReadingDate!=null?
                                                            (gas?.currentReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>


                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.previousMeterReading!=undefined?gas.previousMeterReading
                                                            +' m³'
                                                            :'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.currentMeterReading
                                                            !=undefined?gas.currentMeterReading +' m³':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 "></div>
                                            <div class=" ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.noofUnitsConsumed?gas.noofUnitsConsumed:'-'}}</p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{gas?.unitRate!=undefined?(gas.unitRate
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    <div class="row" *ngIf="gas.otherCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of gas.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!--  <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{gas.billAmount!=undefined?(gas.billAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- <div class="row" *ngIf="bills.generalCharges.length>0">
                                    <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                        <img src="../../../../assets/images/brands/rupee.png">
                                        <span class="font-weight-bold ms-3 font-size-15">Other Charges </span>
                                    </div>
                                </div> -->
                                <!--  <div class="row" *ngIf="bills.generalCharges.length>0">
                                    <table class="table custom-border-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Service Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">GST (%)</th>
                                                <th scope="col">GST Amount</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let charge of bills.generalCharges">
                                                <td>{{charge.name}}</td>
                                                <td>{{charge.amount}}</td>
                                                <td>{{charge.gst}}</td>
                                                <td>{{charge.gstAmt}}</td>
                                                <td>{{charge.total}}</td>

                                            </tr>
                                            <tr>
                                                <th scope="row"></th>
                                                <td colspan="1"></td>
                                                <td></td>
                                                <td class="font-weight-bolder">Total Amount</td>
                                                <td class="font-weight-bolder">
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    |currency:'INR':true):'0'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                </div> -->




                            </div>
                            <div class="col-md-12 ">
                                <div class="d-flex justify-content-around penaltyBill">
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                        <p class="font-size-18 font-weight-bold">
                                            {{bills?.totalAmountwithoutpenalty!=undefined?(bills.totalAmountwithoutpenalty
                                            |currency:'INR':true):'0'}}</p>
                                    </div>
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                        <p class="font-size-18 font-weight-bold">
                                            {{bills?.gas[0]?.penaltyAmount!=undefined?(bills?.gas[0]?.penaltyAmount
                                            |currency:'INR':true):'0'}} </p>
                                    </div>
                                    <div class="text-center">
                                        <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                        <p class="font-size-18 font-weight-bold">
                                            {{bills?.totalAmount!=undefined?(bills.totalAmount
                                            |currency:'INR':true):'0'}}</p>
                                    </div>
                                </div>
                            </div>



                        </accordion-group>
                        }

                        <!--  Water bill  -->

                        @if(bills?.water.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item2>

                            <div accordion-heading class="d-flex flex-grow-1">


                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img" src="../../../../assets/images/brands/water.png">
                                            <span class="text-primary font-size-15">Water : Billing
                                                Cart({{bills?.billNo}})
                                            </span>
                                        </div>

                                    </div>
                                    <div *ngFor="let water of bills?.water">

                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{bills?.dueDate!=null?
                                                        (bills?.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Units Consumed</label>
                                                    <p>{{water?.noofUnitsConsumed?water.noofUnitsConsumed:'-'}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>

                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        |currency:'INR':true):'0'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item2?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div *ngIf="bills?.water.length>0">
                                <div class="row" *ngFor="let water of bills?.water">
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class=" pt-2 ">

                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end">{{water?.previousReadingDate!=null?
                                                            (water?.previousReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Reading Date</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">{{water?.currentReadingDate!=null?
                                                            (water?.currentReadingDate | date:'d MMMM yyyy')
                                                            :'' }}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Previous Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.previousMeterReading!=undefined?water.previousMeterReading
                                                            +' L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Current Meter Reading</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.currentMeterReading!=undefined?water.currentMeterReading
                                                            +' L':'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="custom-list-bill-view p-2 rounded mt-2">
                                            <div class="pt-2 ">
                                            </div>
                                            <div class=" ">
                                                <div class="d-flex align-items-center mb-1">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Consumed</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.noofUnitsConsumed!=undefined?water.noofUnitsConsumed:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 mb-2 pb-5">
                                                    <div class="flex-grow-1 me-1">
                                                        <label class="form-label">Unit Rate</label>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="float-end mb-0">
                                                            {{water?.unitRate!=undefined?(water.unitRate
                                                            |currency:'INR':true):'0'}}
                                                        </p>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="row" *ngIf="water.otherCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of water.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{water?.billAmount!=undefined?(water.billAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>

                                    <!--  <div class="row" *ngIf="bills.generalCharges.length>0">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/rupee.png">
                                            <span class="font-weight-bold ms-3 font-size-15">Other Charges </span>
                                        </div>
                                    </div> -->
                                    <!--  <div class="row" *ngIf="bills.generalCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of bills.generalCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div class="col-md-12">
                                            <div class="text-center totalBill ">
                                                <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    |currency:'INR':true):'0'}} </p>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-md-12 ">
                                        <div class="d-flex justify-content-around penaltyBill">
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmountwithoutpenalty!=undefined?(totalAmountwithoutpenalty
                                                    |currency:'INR':true):'0'}}</p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.water[0]?.penaltyAmount!=undefined?(bills?.water[0]?.penaltyAmount
                                                    |currency:'INR':true):'0'}} </p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                    |currency:'INR':true):'0'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </accordion-group>

                        }
                        <!--  Electricity bill  -->
                        @if(bills?.electricity.length>0){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item3>

                            <div accordion-heading class="d-flex flex-grow-1">


                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img"
                                                src="../../../../assets/images/brands/electricity.png">
                                            <span class="text-primary font-size-15">Electricity : Billing Cart
                                                ({{bills?.billNo}})
                                            </span>
                                        </div>

                                    </div>
                                    <div *ngFor="let ele of bills?.electricity">

                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{bills.electricity[0]?.dueDate!=null?
                                                        (bills.electricity[0]?.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Units Consumed (DG/EB)</label>
                                                    <p>{{ele?.noofElectricityUnitsConsumedEB?ele.noofElectricityUnitsConsumedDG:'-'}}/{{ele?.noofElectricityUnitsConsumedEB?ele.noofElectricityUnitsConsumedEB:'-'}}
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>

                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        |currency:'INR':true):'0'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>

                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf="bills?.electricity.length>0">
                                <div *ngFor="let ele of bills?.electricity let i=index">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" pt-2 ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingEB!=undefined?ele.electricityPreviousMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Meter Reading
                                                                DG</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityPreviousMeterReadingDG
                                                                !=undefined?ele.electricityPreviousMeterReadingDG +'
                                                                kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingEB!=undefined?ele.electricityCurrentMeterReadingEB
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex  align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Meter Reading DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.electricityCurrentMeterReadingDG!=undefined?ele.electricityCurrentMeterReadingDG
                                                                +' kWh':'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">No.Of Units Consumed EB</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedEB!=undefined?ele.noofElectricityUnitsConsumedEB:'-'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label ">No. Of Units Consumed DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.noofElectricityUnitsConsumedDG!=undefined?ele.noofElectricityUnitsConsumedDG:'-'}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate EB </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateEB!=undefined?(ele.unitRateEB
                                                                |currency:'INR':true):'0'}}</p>
                                                            <!--  <p class="float-end">{{ele?.unitRateEB?ele.unitRateEB:'-'}}</p> -->
                                                        </div>
                                                    </div>

                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Unit Rate DG </label>
                                                        </div>
                                                        <div class="flex-grow-1">

                                                            <p class="float-end mb-0">
                                                                {{ele?.unitRateDG!=undefined?(ele.unitRateDG
                                                                |currency:'INR':true):'0'}}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Previous Reading Date</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.previousReadingDate!=null?
                                                                (ele?.previousReadingDate | date:'d MMMM yyyy')
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Current Reading Date </label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.currentReadingDate!=null?
                                                                (ele?.currentReadingDate | date:'d MMMM yyyy')
                                                                :'' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="custom-list-bill-view p-2 rounded mt-2">
                                                <div class=" ">
                                                    <div class="d-flex align-items-center mb-1">
                                                        <div class="flex-grow-1 me-1">
                                                            <label class="form-label">Bill Amount</label>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <p class="float-end mb-0">
                                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                                |currency:'INR':true):'0'}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="ele.otherCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of ele.otherCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                    <div class="col-md-12">
                                        <div class="text-center totalBill ">
                                            <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                {{ele?.billAmount!=undefined?(ele.billAmount
                                                |currency:'INR':true):'0'}} </p>
                                        </div>
                                    </div>
                                    <!--  <div class="row" *ngIf="bills.generalCharges.length>0">
                                        <div class="d-flex custom-list-bill w-100 align-items-center p-2 mt-3">
                                            <img src="../../../../assets/images/brands/rupee.png">
                                            <span class="font-weight-bold ms-3 font-size-15">Other Charges </span>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row" *ngIf="bills.generalCharges.length>0">
                                        <table class="table custom-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Service Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">GST (%)</th>
                                                    <th scope="col">GST Amount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let charge of bills.generalCharges">
                                                    <td>{{charge.name}}</td>
                                                    <td>{{charge.amount}}</td>
                                                    <td>{{charge.gst}}</td>
                                                    <td>{{charge.gstAmt}}</td>
                                                    <td>{{charge.total}}</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td colspan="1"></td>
                                                    <td></td>
                                                    <td class="font-weight-bolder">Total Amount</td>
                                                    <td class="font-weight-bolder">
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div class="col-md-12">
                                            <div class="text-center totalBill ">
                                                <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                    {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                    |currency:'INR':true):'0'}} </p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 ">
                                        <div class="d-flex justify-content-around penaltyBill">
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmountwithoutpenalty!=undefined?(totalAmountwithoutpenalty
                                                    |currency:'INR':true):'0'}}</p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.electricity[i]?.penaltyAmount!=undefined?(bills?.electricity[i]?.penaltyAmount
                                                    |currency:'INR':true):'0'}} </p>
                                            </div>
                                            <div class="text-center">
                                                <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                <p class="font-size-18 font-weight-bold">
                                                    {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                    |currency:'INR':true):'0'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </accordion-group>
                        }
                        <!-- other charges -->
                        @if(bills?.isOtherBill){
                        <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item7>


                            <div accordion-heading class="d-flex flex-grow-1">


                                <div class="flex-grow-1 custom-bg-body pt-2 ">
                                    <div class="d-flex flex-grow-1 align-items-center ms-3">
                                        <div class="flex-grow-1">
                                            <img class="utility-img"
                                                src="../../../../assets/images/brands/electricity.png">
                                            <span class="text-primary font-size-15">Other Charges : Billing Cart
                                                ({{bills?.billNo}})
                                            </span>
                                        </div>

                                    </div>
                                    <div>

                                        <div class="row mx-2 mt-3 text-accordian">
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Bill Date</label>
                                                    <p>{{bills?.billGeneratedDate!=null?
                                                        (bills?.billGeneratedDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Due Date</label>
                                                    <p>{{bills.dueDate!=null?
                                                        (bills.dueDate | date:'d MMMM yyyy')
                                                        :'' }}
                                                    </p>
                                                </div>
                                            </div>


                                            <div class="col-lg-3  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Subtotal</label>
                                                    <p>
                                                        {{bills?.billAmount!=undefined?(bills.billAmount
                                                        |currency:'INR':true):'0'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-center">
                                        <img src="../../../../assets/images/brands/utility.png" class="me-2 w-3">
                                        <p class="text-decoration-underline card-title font-size-15">Consumer Details
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/user.png"
                                                            class="w-80">
                                                    </div>

                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Consumer Name</label>
                                                        <p>{{bills?.consumerName?bills.consumerName:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/apartment.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Flat No.</label>
                                                        <p>{{bills?.residentialUnit?bills.residentialUnit:'-'}}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4  mb-3">
                                            <div class="mb-3">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 me-1">
                                                        <img src="../../../../assets/images/brands/bill.png"
                                                            class="w-80">
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <label class="form-label">Bill No.</label>
                                                        <p>{{bills?.billNo?bills.billNo:'-'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>








                            <!--  <div accordion-heading class="d-flex flex-grow-1">


                                <div class="d-flex flex-grow-1 align-items-center ms-3">
                                    <div class="flex-grow-1">
                                        <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                        <span class="text-primary font-size-16">Other Charges </span>
                                    </div>
                                </div>


                                <div class="d-flex align-items-center">
                                    <i class="mdi font-size-24"
                                        [ngClass]="!item7?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                                </div>
                                </div> -->

                            <div class="row custom-list-bill-view p-2 rounded mt-2">
                                <div class="col-lg-12">
                                    <div class="">
                                        <div class="row">
                                            <table class="table custom-border-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Service Name</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">GST (%)</th>
                                                        <th scope="col">GST Amount</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let charge of bills?.generalCharges">
                                                        <td>{{charge.name}}</td>
                                                        <td>{{charge.amount}}</td>
                                                        <td>{{charge.gst}}</td>
                                                        <td>{{charge.gstAmt}}</td>
                                                        <td>{{charge.total}}</td>

                                                    </tr>
                                                    <tr>
                                                        <th scope="row"></th>
                                                        <td colspan="1"></td>
                                                        <td></td>
                                                        <td class="font-weight-bolder">Total Amount</td>
                                                        <td class="font-weight-bolder">
                                                            {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                            |currency:'INR':true):'0'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            <div class="col-md-12">
                                                <div class="text-center totalBill ">
                                                    <p class="mb-0 font-size-10 font-weight-bold ">Bill Amount :
                                                        {{bills.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="d-flex justify-content-around penaltyBill">
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Bill Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.totalGeneralCharge!=undefined?(bills.totalGeneralCharge
                                                        |currency:'INR':true):'0'}}</p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Penalty Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.gas[0]?.penaltyAmount!=undefined?(bills?.gas[0]?.penaltyAmount
                                                        |currency:'INR':true):'0'}} </p>
                                                </div>
                                                <div class="text-center">
                                                    <p class="font-size-16 font-weight-bolder">Total Bill Amount</p>
                                                    <p class="font-size-18 font-weight-bold">
                                                        {{bills?.totalAmount!=undefined?(bills.totalAmount
                                                        |currency:'INR':true):'0'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </accordion-group>
                        }
                    </div>

                    }
                    <!-- Bill Generate -->
                    <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item3>

                        <div accordion-heading class="d-flex flex-grow-1">

                            <div class="flex-grow-1 custom-bg-body pt-2 ">
                                <div class="d-flex flex-grow-1 align-items-center ms-3">
                                    <div class="flex-grow-1">
                                        <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                        <span class="text-primary font-size-16"> Bill Generate </span>
                                    </div>

                                </div>



                            </div>
                            <div class="d-flex align-items-center">
                                <i class="mdi font-size-24"
                                    [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>

                            </div>
                        </div>
                        <div class="row custom-list-bill-view p-2 rounded mt-2">
                            <div class="col-lg-12">

                                <div class="row">
                                    <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Last Month Bill Generated Date</label>
                                            <p>{{paymentDetails?.lastBillGeneratedDate!=null?
                                                (paymentDetails.lastBillGeneratedDate |date)
                                                :'' }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Bill Generation Date </label>
                                            <p>{{paymentDetails?.billGeneratedDate!=null?
                                                (paymentDetails.billGeneratedDate |date)
                                                :'' }}
                                            </p>

                                        </div>
                                    </div>

                                    <div class="col-lg-3  mb-3">
                                        <div class="mb-3">
                                            <label class="form-label">Billed Dates</label>
                                            <p>{{paymentDetails?.billDays?paymentDetails.billDays:'-'}}
                                            </p>
                                        </div>
                                    </div>



                                </div>



                            </div>
                        </div>

                    </accordion-group>

                    <!-- paymnet Deatils  -->
                    <accordion-group [isOpen]="isAllOpen" class="accordion-item mb-3 panel-open" #item6>

                        <div accordion-heading class="d-flex flex-grow-1">

                            <div class="flex-grow-1 custom-bg-body pt-2 ">
                                <div class="d-flex flex-grow-1 align-items-center ms-3">
                                    <div class="flex-grow-1">
                                        <img class="utility-img" src="../../../../assets/images/brands/group.png">
                                        <span class="text-primary font-size-16">Payment Information</span>
                                    </div>

                                </div>



                            </div>
                            <div class="d-flex align-items-center">
                                <i class="mdi font-size-24"
                                    [ngClass]="!item6?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </div>
                        </div>
                        <div class="row custom-list-bill-view p-2 rounded mt-2">
                            <div class="col-lg-12">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Mode of Payment</label>
                                                <p>{{paymentDetails?.modeOfPayment?paymentDetails.modeOfPayment:'-'}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Mobile App / Manual </label>
                                                <p>{{paymentDetails?.paymentType?paymentDetails.paymentType:'-'}}
                                                </p>

                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment Link </label>
                                                <p> <a
                                                        [href]="'https://www.domainname.com/?q='">{{paymentDetails?.paymentType?paymentDetails.paymentType:'-'}}</a>
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment ID </label>
                                                <p>{{paymentDetails?.paymentId?paymentDetails.paymentId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Order ID</label>
                                                <p>{{paymentDetails?.orderId?paymentDetails.orderId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Payment Date</label>
                                                <p>{{paymentDetails?.paymentDate!=null?
                                                    (paymentDetails.paymentDate |date)
                                                    :'' }}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Settlement ID </label>
                                                <p>{{paymentDetails?.settlementId?paymentDetails.settlementId:'-'}}
                                                </p>

                                            </div>
                                        </div>


                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Settlement Date</label>
                                                <p>{{paymentDetails?.settlementDate!=null?
                                                    (paymentDetails.settlementDate |date)
                                                    :'' }}
                                                </p>

                                            </div>
                                        </div>

                                        <div class="col-lg-3  mb-3">
                                            <div class="mb-3">
                                                <label class="form-label">Total Amount Paid</label>
                                                <p> {{paymentDetails?.totalAmount!=undefined?(paymentDetails.totalAmount
                                                    |currency:'INR':true):'0'}}
                                                </p>

                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>


                    </accordion-group>


                </accordion>


            </div>


        </div>
    </section>
    <div class="card-body">
        @if(billList && billList.length<1){ <div class="text-center">
            <!-- <img src="assets/images/logo-dark.png" alt="" height="20" class="auth-logo-dark mx-auto">
                        <img src="assets/images/logo-light.png" alt="" height="20" class="auth-logo-light mx-auto"> -->
            <!-- <img src="../../../assets/images/nodata.jpg" alt="" height="250"> -->
            <h4 class="mt-5">No Data Found</h4>
    </div>
    }