<div class="container-fluid">
    <!--  <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    </div> -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <form>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="mb-3">
                            <label class="form-label">From Date -To Date </label>
                            <!-- <input type="text" class="form-control date" [(ngModel)]="billdate"
                                (bsValueChange)="selectDates($event)" name="daterange" autocomplete="off"
                                bsDaterangepicker [bsConfig]="datePickerConfig" /> -->

                            <div class="position-relative">
                                <input type="text" class="form-control date"
                                    [value]="billdate ? (formatDateRange(billdate)) : ''" placeholder="Select Date"
                                    name="daterange" autocomplete="off" bsDaterangepicker
                                    (bsValueChange)="selectDates($event)" [bsConfig]="datePickerConfig"
                                    [(bsValue)]="billdate" />
                                <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                                <!-- Clear icon -->
                                <span class="bx bx-x inputIcon clear-icon" *ngIf="billdate.length>0 "
                                    (click)="clearDate()"></span>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 d-flex align-items-center">
                        <button class="btn btn-primary btn-md mt-2" (click)="filterBasedOnDate(1)">Filter</button>
                        <button class="btn btn-secondary btn-md mt-2 ms-2" (click)="filterBasedOnDate(0)">Clear</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>
                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="billHistoryData" [reorderable]="true" filterable="menu"
                            [columnMenu]="{ filter: true }" scrollable="scrollable" [resizable]="true" [pageSize]="10"
                            [pageable]="{
                              buttonCount: buttonCount,
                              info: info,
                              type: type,
                              pageSizes: pageSizes,
                              previousNext: previousNext
                            }" [selectable]="selectableSettings" [filter]="billInvoiceState.filter" [sortable]="true"
                            [sort]="billInvoiceState.sort" [skip]="skip" [sort]="billInvoiceState.sort"
                            (filterChange)="onBillInvoiceFilterChange($event)" (sortChange)="onSortChange($event)"
                            (selectionChange)="onSelect($event)" (pageChange)="billHistoryonPageChange($event)"
                            (dataStateChange)="billHostoryStateChange($event)">
                            <ng-template kendoGridToolbarTemplate>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->

                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [style.height.px]="35"
                                        [(ngModel)]="searchedValue" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <kendo-grid-spacer></kendo-grid-spacer>

                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                    (click)="onExport('Excel')">
                                    <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                                </button>
                            </ng-template>

                            <!-- Hidden grid for PDF/Excel export -->
                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                <kendo-grid [kendoGridBinding]="
                                        selectedRowdata.length > 0
                                            ? selectedRowdata
                                            : exportData.length > 0
                                            ? exportData
                                            : billHistoryData.data
                                    " #pdfGrid>
                                    <kendo-grid-column field="invoiceNo" title="Invoice No"></kendo-grid-column>
                                    <kendo-grid-column field="residentialUnit"
                                        title="Residential Unit"></kendo-grid-column>
                                    <kendo-grid-column field="consumerName" title="Consumer"></kendo-grid-column>
                                    <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                    <kendo-grid-column field="macAddress" title="Meter No"></kendo-grid-column>
                                    <kendo-grid-column field="totalAmount" title="Total Amount"></kendo-grid-column>
                                    <kendo-grid-column field="currentInvoiceDate"
                                        title=" Invoice Date"></kendo-grid-column>
                                    <kendo-grid-column field="modeOfPayment"
                                        title="Mode of Payment"></kendo-grid-column>
                                    <!-- <kendo-grid-column field="transactionId" title="Transaction Id"></kendo-grid-column>
                                    <kendo-grid-column field="paymentStatus" title="Payment Status"></kendo-grid-column> -->
                                    <kendo-grid-excel fileName="billInvoice.xlsx"></kendo-grid-excel>

                                </kendo-grid>
                            </div>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="invoiceNo" title="Invoice No"></kendo-grid-column>
                            <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
                            <kendo-grid-column field="currentInvoiceDate" title="Invoice Date" filter="date">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.currentInvoiceDate | date: 'dd MMM yyyy hh:mm a'}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Meter No" [hidden]="true"></kendo-grid-column>

                            <kendo-grid-column field="totalAmount" title="Total Amount" filter="numeric">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.billAmount | currency: 'INR':'symbol':'1.2-2' }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="modeOfPayment" title="Mode of Payment"
                                [hidden]="true"></kendo-grid-column>

                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <!-- Info Icon (Clickable) -->
                                    <div class="d-flex">
                                        <span kendoTooltip title="Go to Info" class="me-2"
                                            (click)="onViewClick(dataItem)" style="cursor: pointer; font-size: 20px;">
                                            <i class="fas fa-info-circle text-success"></i>
                                        </span>
                                        <span kendoTooltip title="Download Receipt" class="ms-2"
                                            (click)="DownloadInvoice(dataItem)"
                                            style="cursor: pointer; font-size: 20px;">
                                            <i class="fa fa-download text-success" aria-hidden="true"></i>
                                        </span>

                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>

                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #paymentModel>
    <div class="modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="main-payment">
                    <div class="align-center">
                        <div class="tick-success">
                            <img src="../../../assets/images/tick-circle.png">
                        </div>
                    </div>
                    <div class="text-center mt-5">
                        <h3>Payment Success</h3>
                        <p class="text-muted">Your payment has been successfully done</p>
                        <hr class="mx-auto">
                    </div>
                    <div class="text-center ">
                        <p class="text-muted font-size-18">Total Amount</p>

                        <h3> {{selsetedInvoicedetails?.totalAmount!=undefined?(selsetedInvoicedetails.totalAmount
                            |currency:'INR':true):'0'}}
                        </h3>
                        
                        <hr class="mx-auto">
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="p-2 mt-3 mx-2">
                                <table class="table">

                                    <tr>
                                        <td class="text-muted">Name</td>
                                        <td class="float-end font-weight-bolder  font-size-14">
                                            {{selsetedInvoicedetails?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">Flat#</td>
                                        <td class="float-end font-weight-bolder ">
                                            {{selsetedInvoicedetails?.residentialUnit}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">Payment ID</td>
                                        <td class="float-end font-weight-bolder ">{{selsetedInvoicedetails?.paymentId}}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-muted">Date & Time</td>
                                        <p>
                                        </p>
                                        <td class="float-end font-weight-bolder ">
                                            {{selsetedInvoicedetails?.lastRechargeDate!=null?
                                            (selsetedInvoicedetails?.lastRechargeDate | date: 'dd MMM yyyy hh:mm a')
                                            :'' }}</td>
                                    </tr>

                                </table>


                            </div>
                        </div>

                    </div>

                    <div class=" row mt-4">
                        <div class="col-lg-12 w-80 mx-auto text-center d-flex flex-column">
                            <p class="text-primary">Thank you for choosing Ripple Services</p>
                            <button class="btn btn-primary" (click)="downloadInvoice()"><i
                                    class="bx bx-download me-2"></i>
                                Download Receipt</button>
                            <button class="btn btn-outline-primary mt-2 mb-4" (click)="closeAlert()"> Ok</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>

</ng-template> -->