import { Component, Input, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SweetAlertIcon } from '../../constants/enum';
import { LoaderService } from '../../../core/services/loader.service';
import { Router } from '@angular/router';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-wallet-history-expended',
  templateUrl: './wallet-history-expended.component.html',
  styleUrl: './wallet-history-expended.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class WalletHistoryExpendedComponent {

  @Input() public wallet!: Wallet[];
  public walletHistoryState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public walletHistoryExtendedgridData: GridDataResult = { data: [], total: 0 };
  public wallet_historyPageSize = 10;
  public walletskip = 0;
  totalwallethistoryCount: number = 0;
  public walletfilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  currentUser: any
  billingType: any
  constructor(private router: Router, private loaderService: LoaderService,) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.billingType = this.currentUser.project?.billingType;
    this.loaderService.setLoading(true);
    // Consider moving data loading logic to ngOnInit if you need to load data
    this.loadWalletHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['wallet']) {
      console.log('Wallet changed:', this.wallet);
      this.loadWalletHistory();
    }
  }

  private loadWalletHistory(): void {
    const state: State = {
      take: this.wallet_historyPageSize,
      sort: [],  // Add sorting logic if needed
      filter: this.filter,
    };

    if (this.wallet) {
      const processedData = process(this.wallet, state);
      this.walletHistoryExtendedgridData = {
        data: processedData.data,
        total: this.totalwallethistoryCount,
      };
    }
    this.loaderService.setLoading(false);
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  viewconsumption(value: any) {
    let consumptionDetails = {
      ...value,
      id: value.billId,
      isWallet: true  // Add or modify properties as needed
    };
    this.router.navigate(['/consumptionHistory/consumptionView'], {
      state: { consumption: consumptionDetails },
    });
  }

  viewBillHistory(value: any) {
    console.log(value)
    const billHistoryDetails = {
      ...value,
      id: value.billId,
      isWallet: true  // Add or modify properties as needed
    };
    this.router.navigate(['/billDetail'], {
      state: { billHostorydeatils: billHistoryDetails },
    });
  }




}

export interface Wallet {
  availableBalance: number;
  billNo: string;
  consumption: string;
  id: number;
  meterNo: string;
  residentialUnit: string;
  unitRate: string;
  unitRateEB: string;
  unitRateDG: string;
  utility: string;
}
