import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { Subscription, take } from 'rxjs';
import { getDueBillsResponse } from '../../../store/customer/consumer-selector';
import { getDueBills } from '../../../store/customer/consumer.action';
import { getBillsByIdlist, resetbillsState } from '../../../store/Bills/bills.action';
import { getBillsByIdResponse } from '../../../store/Bills/bills-selector';
@Component({
  selector: 'app-bills-view',
  templateUrl: './bills-view.component.html',
  styleUrl: './bills-view.component.scss'
})
export class BillsViewComponent {

  @Input() billsDataById: any;
  currentUser: any;
  projectId: string = '';
  billGenerationType: string = '';
  billList: any[] = [];
  paymentDetails: any;
  totalAmountwithoutpenalty: number = 0
  isAllOpen: boolean = true;
  breadCrumbItems: Array<{}> | undefined;
  private getBillsById$: Subscription = new Subscription;


  constructor(
    private loaderService: LoaderService, private store: Store,
    private sharedService: SharedService,

  ) {
    console.log(this.billsDataById)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.billGenerationType = this.currentUser?.project.billGenerationType;
    this.sharedService.billDetails$.subscribe((res: any) => {

      this.billsDataById = res;
      console.log(this.billsDataById)
    })
    if (Object.keys(this.billsDataById).length > 0) {
      this.store.dispatch(resetbillsState());
    }
    this.getbillsByid()
  }

  ngOnDestroy() {
    this.getBillsById$.unsubscribe();  // Or set it to [] if you prefer an empty array
  }

  ngOnInit() {
    this.loaderService.setLoading(true);
    //this.store.dispatch(getDueBills({ ProjectId: this.projectId }));
    this.store.dispatch(getBillsByIdlist({ id: this.billsDataById.id }));
  }
  onCheckboxClick(event: Event, isChecked: boolean, index: number, billNo: string) {
    event.stopPropagation();


  }
  isOtherBill: boolean = false
  ngAfterViewInit() {
    console.log(this.billsDataById)
  }
  getbillsByid() {
    this.getBillsById$ = this.store.pipe(select(getBillsByIdResponse)).subscribe((res: any) => {
      if (res) {
        let tempresponse: any[] = [];
        tempresponse.push(res)
        console.log(tempresponse)


        this.billList = tempresponse.map((bill: any, index: number) => {
          this.paymentDetails = {
            paymentDate: bill.paymentDate,
            orderId: bill.orderId,
            paymentLink: bill.paymentLink,
            paymentStatus: bill.paymentStatus,
            paymentType: bill.paymentType,
            penaltyDate: bill.penaltyDate,
            settlementId: bill.settlementId,
            settlementDate: bill.settlementDate,
            modeOfPayment: bill.modeOfPayment,
            billDays: bill.billDays,
            lastBillGeneratedDate: bill.lastBillGeneratedDate,
            currentBillGeneratedDate: bill.currentBillGeneratedDate,
            paidAmount: bill.paidAmount,
            transactionId: bill.transactionId,
            billNo: bill.billNo,
            billGeneratedDate: bill.billGeneratedDate,
            totalAmount: bill.totalAmount,

          }
          console.log(this.paymentDetails.billDays)
          console.log(this.paymentDetails.lastBillGeneratedDate)
          console.log(this.paymentDetails.currentBillGeneratedDate)
          var gasbillAmount = 0;
          var waterbillAmount = 0;
          var elebillAmount = 0;
          var waterpenaltyAmount = 0;
          var elepenaltyAmount = 0;
          var gaspenaltyAmount = 0;

          bill.gas.forEach((gas: any, index: number) => {
            gasbillAmount = Number(gas.billAmount) || 0;
            gaspenaltyAmount = Number(gas?.penaltyAmount) || 0;
          })
          bill.water.forEach((water: any, index: number) => {
            waterbillAmount = Number(water?.billAmount) || 0;
            waterpenaltyAmount = Number(water?.penaltyAmount) || 0;
          })
          bill.electricity.forEach((ele: any, index: number) => {
            elebillAmount = Number(ele?.billAmount) || 0;
            elepenaltyAmount = Number(ele?.penaltyAmount) || 0;
          })

          this.totalAmountwithoutpenalty = 0;

          const totalGeneralCharge = Number(bill.totalGeneralCharge) || 0;

          if (this.billGenerationType == 'Combined') {
            this.totalAmountwithoutpenalty = totalGeneralCharge + gasbillAmount + waterbillAmount + elebillAmount;
          } else {
            this.totalAmountwithoutpenalty = 0;
            switch (bill?.utility) {

              case 'Electricity':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + elebillAmount + elepenaltyAmount;
                break;
              case 'Water':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + waterbillAmount + waterpenaltyAmount;
                break;
              case 'Gas':
                this.totalAmountwithoutpenalty = this.totalAmountwithoutpenalty + gasbillAmount + gaspenaltyAmount;
                break;
            }
          }
          return { ...bill, totalAmountwithoutpenalty: this.totalAmountwithoutpenalty };

        })
        this.loaderService.setLoading(false);
        console.log(this.billList)

      }
    })

  }

}


