import { createFeatureSelector, createSelector } from '@ngrx/store';
import { billsState } from './bills.reducer'


export const selectDataState = createFeatureSelector<billsState>('bills');


export const getBillsResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillsResponse);

export const getManualResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getManualResponse);

export const getAdhoctResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getAdhoctResponse);

export const getBillsByIdResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillsByIdResponse);

export const createinvildBillsResponse = createSelector(
    selectDataState,
    (state: billsState) => state.createinvildBillsResponse);

export const createBillsResponse = createSelector(
    selectDataState,
    (state: billsState) => state.createBillsResponse);

export const getresidentialResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getresidentialResponse);

export const getBillReadingsInfoResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillReadingsInfoResponse);

export const getAdhoctExportResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getAdhoctExportResponse);

export const getManualExportResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getManualExportResponse);

export const getBillsExportResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillsExportResponse);


export const getBillReadingsByMonthResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillReadingsByMonthResponse);


export const getBillReadingsByMonthAdhocResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillReadingsByMonthAdhocResponse);



export const PaycashbillSuccessResponse = createSelector(
    selectDataState,
    (state: billsState) => state.PaycashbillSuccessResponse);

export const getBillsInvoiceResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillsInvoiceResponse);


export const getBillsInvoiceExportResponse = createSelector(
    selectDataState,
    (state: billsState) => state.getBillsInvoiceExportResponse);


//