<div class="row">
  <div class="col-lg-12">
    <form>
      <div class="row">
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label">From Date -To Date </label>
            <!-- <input type="text" class="form-control date" [(ngModel)]="fromDate" (bsValueChange)="selectDates($event)"
              name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig" /> -->

            <div class="position-relative">
              <input type="text" class="form-control date" [value]="fromDate ? (formatDateRange(fromDate)) : ''"
                placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                [bsConfig]="datePickerConfig" [(bsValue)]="fromDate" />
              <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
              <!-- Clear icon -->
              <span class="bx bx-x inputIcon clear-icon" *ngIf="fromDate.length>0" (click)="clearDate()"></span>
            </div>

          </div>
        </div>
        <div class="col-lg-2 d-flex align-items-center">
          <button class="btn btn-primary btn-md mt-2" (click)="filterBasedOnDate(1)">Filter</button>
          <button class="btn btn-secondary btn-md mt-2 ms-2" (click)="filterBasedOnDate(0)">Clear</button>
        </div>
      </div>
    </form>

  </div>
</div>

<section>
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
    <kendo-grid [data]="walletHistorygridData" scrollable="scrollable" [navigable]="true" kendoGridFocusable
      [pageSize]="wallet_historyPageSize" [sortable]="true" [selectable]="true"
      [pageable]="{ buttonCount: buttonCount, info: info, type: type,pageSizes: pageSizes, previousNext: previousNext}"
      [reorderable]="true" filterable="menu" [filter]="walletHisoryState.filter" [skip]="skip"
      [sort]="walletHisoryState.sort" [columnMenu]="{ filter: true }" (sortChange)="onWalletSortChange($event)"
      [resizable]="true" (filterChange)="onWalletHistoryFilterChange($event)"
      (pageChange)="walletConfigPageChange($event)" (dataStateChange)="walletDataStateChange($event)"
      (selectionChange)="walletHistoryselectionChanged($event)">
      <!-- (excelExport)="onExcelExport($event)" -->


      <ng-template kendoGridToolbarTemplate>
        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..." [(ngModel)]="walletSearch"
          (valueChange)="onWalletDataSearchFilter($event)"></kendo-textbox> -->
        <div class="search_icon">
          <kendo-textbox [style.width.px]="250" [clearButton]="true" (valueChange)="onValueChange($event)"
            [(ngModel)]="walletSearch" [style.height.px]="35" placeholder="Search in all columns...">
          </kendo-textbox>

          <button class="btn btn-primary btn-md border-left-none" (click)="onWalletDataSearchFilter($event)">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <kendo-grid-spacer></kendo-grid-spacer>
        <div class="sm-device d-flex justify-content-between">
          <!-- <button kendoGridExcelCommand [svgIcon]="fileExcelIcon">
        Export to Excel
      </button> -->

          <button class="btn btn-outline-primary btn-sm" (click)="ConsumptionExport() "
            *ngIf="this.billingType == 'Prepaid'">
            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
          </button>

          <button class="btn btn-outline-primary btn-sm" (click)="billHistoryExport()"
            *ngIf="this.billingType == 'Postpaid'">
            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
          </button>

        </div>

      </ng-template>

      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="60"
        class="no-tooltip"></kendo-grid-checkbox-column>

      <kendo-grid-column field="paidDate" title="Date" filter="date" filter="date">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.paidDate | date: 'dd MMM yyyy '}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="totalAmountDeducted" title="Amount Deducted" filter="numeric">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.totalAmountDeducted | currency: 'INR':'symbol':'1.2-2' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="openingBalance" title="Opening Balance" filter="numeric">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.totalAmountDeducted | currency: 'INR':'symbol':'1.2-2' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="closingBalance" title="Closeing Balance" ilter="numeric">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.totalAmountDeducted | currency: 'INR':'symbol':'1.2-2' }}
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridDetailTemplate let-dataItem="dataItem">
        <app-wallet-history-expended [wallet]="dataItem.walletDtls"></app-wallet-history-expended>
      </ng-template>
      <!--  <kendo-grid-excel fileName="Products.xlsx"></kendo-grid-excel> -->
    </kendo-grid>
  </div>
</section>