import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from '../../constants/enum';

@Component({
  selector: 'app-rp-breadcrumb',
  templateUrl: './rp-breadcrumb.component.html',
  styleUrl: './rp-breadcrumb.component.scss',
})
export class RpBreadcrumbComponent {
  @Input() breadcrumbItems: any;
  @Input() title: string | undefined;
  currentUserData: any;
  constructor(private router: Router) { }

  ngOnInit() { }
  gotoDashBoard() {
    console.log(this.currentUserData);
    localStorage.removeItem('selectedTabIndex');
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    const role = this.currentUserData?.currentUserRoleDetails?.role;

    switch (role) {
      case Roles.ADMIN:
        this.router.navigate(['/admindashboard']);
        break;
      case Roles.SUPERADMIN:
        this.router.navigate(['/dashboard']);
        break;
      case Roles.CONSUMER:
        this.router.navigate(['/consumerdashboard']);
        break;
      default:
        console.warn('Unknown role, no dashboard to navigate to.');
        return false;
    }

    return true;
  }
}
