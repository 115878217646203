import { createAction, props } from '@ngrx/store';

// fetch Wallet
export const getWalletlist = createAction(
  '[Users] getwallet',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getWalletSuccess = createAction(
  '[Users] fetch wallet success',
  props<{ getWalletResponse: any }>()
);

export const getWalletFailure = createAction(
  '[Users] fetch wallet failed',
  props<{ getwalletError: any }>()
);

export const getAllWalletlist = createAction(
  '[Users] Allgetwallet',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    projectId: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getAllWalletSuccess = createAction(
  '[Users] fetchAll wallet success',
  props<{ getAllWalletResponse: any }>()
);

export const getAllWalletFailure = createAction(
  '[Users] fetch All wallet failed',
  props<{ getAllwalletError: any }>()
);

// fetch  Wallet Histroy
export const getWalletHistorylist = createAction(
  '[Users] getwallet history',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    walletId: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getWalletHistorySuccess = createAction(
  '[Users] fetch getwallet history success',
  props<{ getWalletHistoryResponse: any }>()
);
export const getWalletHistoryFailure = createAction(
  '[Users fetch getwallet history failed]',
  props<{ getwalletHistoryError: any }>()
);

export const getAllWalletHistorylist = createAction(
  '[Users] getAllwallet history',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    walletId: string;
    filter?: string;
    orderBy?: string;
  }>()
);

export const getAllWalletHistorySuccess = createAction(
  '[Users] fetch getAllwallet history success',
  props<{ getAllWalletHistoryResponse: any }>()
);
export const getAllWalletHistoryFailure = createAction(
  '[Users fetch getAllwallet history failed]',
  props<{ getAllwalletHistoryError: any }>()
);

// fetch  Recharge History
export const getRechargeHistorylist = createAction(
  '[Users] getRechargeHistorylist',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    walletId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getRechargeHistorySuccess = createAction(
  '[Users] fetch getRechargeHistorylist success',
  props<{ getRechargeHistoryResponse: any }>()
);
export const getRechargeHistoryFailure = createAction(
  '[Users fetch getRechargeHistorylist failed]',
  props<{ getRechargeHistoryError: any }>()
);

// fetch all Recharge History
export const getAllRechargeHistorylist = createAction(
  '[Users] getAllRechargeHistorylist',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    walletId: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllRechargeHistorySuccess = createAction(
  '[Users] fetch getAllRechargeHistorylist success',
  props<{ getAllRechargeHistoryResponse: any }>()
);
export const getAllRechargeHistoryFailure = createAction(
  '[Users fetch getAllRechargeHistorylist failed]',
  props<{ getAllRechargeHistoryError: any }>()
);

export const getwalletHistorylist = createAction(
  '[wallet] getWalletHistorylist',
  props<{ consumerId: string; projectId: string }>()
);
export const getwalletHistorylistSuccess = createAction(
  '[wallet] getWalletHistorylist',
  props<{ walletHistroeyResponse: string }>()
);
export const getwalletHistorylistFailure = createAction(
  '[wallet] getWalletHistorylist',
  props<{ walletHistroeyfailure: string }>()
);

export const getwalletPyByCash = createAction(
  '[wallet] getWalletPyByCash ',
  props<{ cashDeatils: any }>()
);
export const getwalletPyByCashSuccess = createAction(
  '[wallet] getWalletPyByCash ',
  props<{ walletPyByCashResponse: string }>()
);
export const getwalletPyByCashFilure = createAction(
  '[wallet] getWalletPyByCash ',
  props<{ walletPyByCashfailure: string }>()
);

//store reset
export const resetState = createAction('[App] Reset State');

export const resetHistoryState = createAction(
  '[App History] Reset  HistoryState'
);
