import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MetersState } from './meters.reducer';

export const getMeterState = createFeatureSelector<MetersState>('meter');

export const getmeterConfigResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getmeterConfigResponse
);

export const getAllmeterConfigResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getAllmeterConfigResponse
);

export const getmeterConsumptionResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getmeterConsumptionResponse
);

export const updateMeterStatusResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.updateMeterStatusResponse
);

export const getAllmeterConsumptionResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getAllmeterConsumptionResponse
);

export const checkMeterStatusResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.checkMeterStatusResponse
);


export const getAdminmeteronOffHistoryResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getAdminmeteronOffHistoryResponse
);

export const getAdminmeteronOffHistoryExportResponse = createSelector(
  getMeterState,
  (state: MetersState) => state.getAdminmeteronOffHistoryExportResponse
);

//getAdminmeteronOffHistoryExportResponse


